.softball-container {
    padding: 3em;
}

.season-container {
    padding-top: 1em;
}

.overall-record {
    font-weight: bold;
}

.percentage {
    font-weight: bold;
    font-size: 85%;
}

.record-breakdown {
    font-style: italic;
    padding-left: .2em;
    font-size: 95%;
}
.season-name {
    font-weight: bold;
}

.season-year {
    font-style: italic;
    padding-left: .5em;
    font-size: 85%;
}

.logo-container {
    border:2px solid black;
}