.svg-container svg {
    display: block;
    margin: 0 auto;
}

.svg-container h2 {
    margin: auto;
    width: 30%;
}

.count-container {
    margin: auto;
    width: 30%;
}

.states-dropdown {
    margin-left: 5em;
    margin-top: 2em;
}

.legend {
    margin-left: 5em;
}

.legend-box {
    margin-top: .5em;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(0, 0, 0, .2);
  }

.legend-text {
    margin-left: 1.5em;
    white-space: nowrap;
}